<template>
  <vue-final-modal
    class="modal-confirm-email"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="modal-confirm-email__container">
      <atomic-icon v-if="!isMobile" class="modal-confirm-email__close" id="close" @click="closeDialog" />

      <div class="modal-confirm-email__title">
        {{ getContent(currentLocaleData, defaultLocaleData, 'checkYourEmail') }}
      </div>

      <div class="modal-confirm-email__text">
        <div>{{ getContent(currentLocaleData, defaultLocaleData, 'emailWasSent') }}</div>

        <span class="modal-confirm-email__text--bold">{{ profile?.email }}</span>

        <span>{{ getContent(currentLocaleData, defaultLocaleData, 'confirmEmail') }}</span>
      </div>

      <button-base type="primary" size="md" class="modal-confirm-email__btn" @click="closeDialog">
        {{ getContent(currentLocaleData, defaultLocaleData, 'gotIt') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import type { IModalsContent } from '~/types';
  import { ModalName } from '@skeleton/consts/modals';

  defineProps<{
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const { getContent } = useProjectMethods();
  const { profile } = storeToRefs(useProfileStore());
  const { closeModal } = useModalStore();
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const closeDialog = () => {
    closeModal(ModalName.CONFIRM_EMAIL);
  };
</script>

<style src="~/assets/styles/components/modal/confirm-email.scss" lang="scss" />
